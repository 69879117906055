import React from "react"
import { Link } from "gatsby"

const Chips = ({text, link}) => {
    return (
      <Link
        to={link}
        className="rounded-[7px] inline-block border border-button-blue px-[24px] py-[4px] text-[13px] leading-[15px] font-bold duration-150 hover:bg-button-blue hover:bg-opacity-13"
      >
          <span className="text-button-blue"> {text} </span>
      </Link>
    )
}

export default Chips