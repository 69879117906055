import React from "react"
import ctl from "@netlify/classnames-template-literals"

const TableHeading = ({ data }) => {
  const tableHeadingRowStyle = ctl(
    `border border-blue rounded-t-xl text-blue bg-white`
  )

  const tableHeadingDataStyle = ctl(
    `md:pt-[62px] md:pb-[35px] pt-[21px] pb-[17px] md:px-6 px-3 text-left border border-blue`
  )

  const tableHeadingData = data

  return (
    <tr className={tableHeadingRowStyle}>
      {tableHeadingData.map((heading, index) => (
        <th key={index} className={tableHeadingDataStyle}>
          {heading}
        </th>
      ))}
    </tr>
  )
}

export default TableHeading
