import React from "react"
import Layout from "../../../layout"
import Container from "../../../utils/container"
import Table from "../table"
import ctl from "@netlify/classnames-template-literals"
import MarketsPageHeader from "../header"
import Chips from "../chips"
import FAQs from "../faq"

const MarketTagsText = {
  indices: "Indices",
  "index-future-diffs": "Index future diffs",
  forex: "Forex",
  commodities: "Commodities",
  "individual-equity-markets": "Individual Equity Markets",
  "cryptocurrency": "Cryptocurrency",
  "metals": "Metals",
  "equity-cfds": "Equity CFDS"
}

const chips = {
  cloudtrade: [
    "indices",
    "index-future-diffs",
    "forex",
    "commodities",
    "individual-equity-markets",
    "cryptocurrency"
  ],
  mt4: [
    "forex",
    "indices",
    "metals",
    "equity-cfds",
    "commodities"
  ]
}

const MarketsSinglePage = ({ pageContext }) => {
  const { name, faqs, table, page, slug, pageTitle, description } = pageContext

  const tableHasData = table.length > 0

  const currentChips = chips[page].filter(chip => chip !== slug)

  return (
    <Layout title={pageTitle} description={description}>
      <MarketsPageHeader page={page} specialMargin />
      <Container size="medium">
        <div className="mb-[40px] md:mb-[80px] flex flex-wrap gap-y-[12px] gap-x-[30px]">
          {
            currentChips.map(chip => (
              <Chips
                text={MarketTagsText[chip]}
                link={`/markets/${page}/${chip}/`}
              />
            ))
          }
        </div>
        <h1 className={headingStyle}>{name}</h1>
      </Container>
      <div className={wrapperStyle}>
        {tableHasData && <div className={orangeBackgroundStyle} />}
        <Container size="medium">
          {tableHasData && (
            <div className={tableWrapperStyle}>
              <Table data={table} />
            </div>
          )}
        </Container>
      </div>
      <Container size="medium">
        <FAQs name={name} faqs={faqs}/>
      </Container>
    </Layout>
  )
}

const headingStyle = ctl(`mb-[20px] md:mb-[23px] md:text-[36px] text-[20px]`)

const wrapperStyle = ctl(`relative`)

const orangeBackgroundStyle = ctl(
  `bg-tangerine shape left-0 table-background w-full opacity-10 top-[50%] translate-y-[-50%]  `
)

const tableWrapperStyle = ctl(`border-blue border rounded-2xl !p-0`)
export default MarketsSinglePage
