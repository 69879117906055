import React, { useState } from "react"
import plusIcon from "../../../../assets/icons/plus.png"
import minusIcon from "../../../../assets/icons/minus.png"
import { motion } from "framer-motion"

const FAQs = ({ name, faqs }) => {
  return (
    <div className='mt-24'>
       <h3 className='text-[36px] font-bold mb-[30px] text-blue'>
         Frequently asked questions about {name}.
       </h3>
      <p className='text-[22px] text-black mb-[100px]'>
        {name}-based questions answered for you in 2 sentences or less.
      </p>
      <div className='grid gap-y-5'>
        {faqs.map(faq => <Question {...faq}/>)}
      </div>
    </div>
  )
}

const Question = ({ question, answer }) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      className={`p-10 border border-button-blue border-opacity-20 cursor-pointer duration-200 bg-button-blue rounded-xl hover:bg-opacity-0 ${open ? 'bg-opacity-0' : 'bg-opacity-[0.05]'}`}
      onClick={() => setOpen(prev => !prev)}
    >
      <header
        className='flex items-center justify-between cursor-pointer'
      >
        <h3 className='font-semibold text-xl text-blue'>
          {question}
        </h3>
        <button className='bg-transparent border-none'>
          {
            open ? (
              <img src={minusIcon} alt="Close Question" />
            ) : (
              <img src={plusIcon} alt="Open Question" />
            )
          }
        </button>
      </header>
      <motion.div
        variants={{
          show: {
            height: 'auto'
          },
          hide: {
            height: 0
          }
        }}
        initial='hide'
        animate={open ? 'show' : 'hide'}
        className='overflow-hidden'
      >
        <p className='text-2xl text-black mt-16'>
          {[].concat(answer).map(data => {
            return renderAnswer(data)
          })}
        </p>
      </motion.div>
    </div>
  )
}

const renderAnswer = (answer) => {
  if(answer.text){
    return <a className='underline' href={answer.link} target='_blank'>{answer.text}</a>
  }

  return answer
}

export default FAQs