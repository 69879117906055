import React from "react"
import TableBody from "./body"
import TableHeading from "./heading"

import ctl from "@netlify/classnames-template-literals"

const Table = ({ data }) => {
  const tableHeading = data[0]
  const tableRows = data.filter((_, index) => index > 0)

  const tableStyle = ctl(
    `border-collapse border w-full md:text-[16px] text-[10px] md:leading-[20px] leading-[14px] border-blue rounded-2xl bg-white overflow-hidden  `
  )

  return (
    <table className={tableStyle}>
      <thead>
        <TableHeading data={tableHeading} />
      </thead>
      <tbody>
        <TableBody data={tableRows} />
      </tbody>
    </table>
  )
}

export default Table
