import React from "react"
import TableRow from "./row"

const TableBody = ({ data }) => {
  const tableBodyData = data
  return (
    <>
      {tableBodyData.map((row, index) => (
        <TableRow data={row} key={index} />
      ))}
    </>
  )
}

TableBody.propTypes = {}

export default TableBody
