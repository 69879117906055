import React from "react"
import ctl from "@netlify/classnames-template-literals"
const TableRow = ({ data }) => {
  const tableRowData = data

  const tableDataStyle = ctl(
    `md:pt-[42px] md:pb-[35px] py-[24px] md:px-6 px-3 text-left border border-blue`
  )
  return (
    <tr>
      {tableRowData.map((rowData, index) => (
        <td
          key={index}
          dangerouslySetInnerHTML={{ __html: rowData }}
          className={tableDataStyle}
        >
          {/* {rowData} */}
        </td>
      ))}
    </tr>
  )
}

export default TableRow
